body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* https://www.w3schools.com/howto/howto_css_hide_scrollbars.asp */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: #fbfbff;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gradient-background {
  max-width: none;
  background-image: radial-gradient(
      at 0% 100%,
      hsla(286, 97%, 90%, 1) 0,
      transparent 50%
    ),
    radial-gradient(at 84% 88%, hsla(196, 99%, 79%, 1) 0, transparent 50%),
    radial-gradient(at 34% 59%, hsla(220, 98%, 83%, 1) 0, transparent 50%),
    radial-gradient(at 100% 0%, hsla(242, 100%, 70%, 1) 0, transparent 50%),
    radial-gradient(at 11% 10%, hsla(220, 98%, 83%, 1) 0, transparent 50%);
}
